@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
	margin: 0;
	font-family: 'Open Sans', sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Raleway', sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

:root {
	/* Care Jobs */
	/* --themecolor: #03CEA4; */
	/* Nursing Jobs */
	--themecolor: #2DC7FF;
	/* Housing Jobs */
	/* --themecolor: #fc5a2b; */
	/* Chef Jobs */
	/*--themecolor: #ED254E;*/
	/* Council Jobs */
	/*--themecolor: #20BF55;*/
	--themecolor2: #1f3d87;
	--bg-white: #fff;
	--rounded-2xl: 1rem;
}

/* padding & margin */
.m-auto {
	margin: auto !important;
}

.m-0 {
	margin: 0 !important;
}

.m-1 {
	margin: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.ml-0 {
	margin-left: 0 !important;
}

.mx-0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.mt-1 {
	margin-top: 0.25rem !important;
}

.mr-1 {
	margin-right: 0.25rem !important;
}

.mb-1 {
	margin-bottom: 0.25rem !important;
}

.ml-1 {
	margin-left: 0.25rem !important;
}

.mx-1 {
	margin-left: 0.25rem !important;
	margin-right: 0.25rem !important;
}

.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}

.mt-2 {
	margin-top: 0.5rem !important;
}

.mr-2 {
	margin-right: 0.5rem !important;
}

.mb-2 {
	margin-bottom: 0.5rem !important;
}

.ml-2 {
	margin-left: 0.5rem !important;
}

.mx-2 {
	margin-right: 0.5rem !important;
	margin-left: 0.5rem !important;
}

.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.mt-3 {
	margin-top: 1rem !important;
}

.mr-3 {
	margin-right: 1rem !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

.ml-3 {
	margin-left: 1rem !important;
}

.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}

.my-3 {
	margin-bottom: 1rem !important;
	margin-top: 1rem !important;
}

.mt-4 {
	margin-top: 1.5rem !important;
}

.mr-4 {
	margin-right: 1.5rem !important;
}

.mb-4 {
	margin-bottom: 1.5rem !important;
}

.ml-4 {
	margin-left: 1.5rem !important;
}

.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}

.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}

.mt-5 {
	margin-top: 3rem !important;
}

.mr-5 {
	margin-right: 3rem !important;
}

.mb-5 {
	margin-bottom: 3rem !important;
}

.ml-5 {
	margin-left: 3rem !important;
}

.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}

.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}

.mt-auto {
	margin-top: auto !important;
}

.mr-auto {
	margin-right: auto !important;
}

.mb-auto {
	margin-bottom: auto !important;
}

.ml-auto {
	margin-left: auto !important;
}

.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

.my-auto {
	margin-bottom: auto !important;
	margin-top: auto !important;
}

.p-0 {
	padding: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.pr-0 {
	padding-right: 0 !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pl-0 {
	padding-left: 0 !important;
}

.px-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.pt-1 {
	padding-top: 0.25rem !important;
}

.pr-1 {
	padding-right: 0.25rem !important;
}

.pb-1 {
	padding-bottom: 0.25rem !important;
}

.pl-1 {
	padding-left: 0.25rem !important;
}

.px-1 {
	padding-left: 0.25rem !important;
	padding-right: 0.25rem !important;
}

.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
}

.pt-2 {
	padding-top: 0.5rem !important;
}

.pr-2 {
	padding-right: 0.5rem !important;
}

.pb-2 {
	padding-bottom: 0.5rem !important;
}

.pl-2 {
	padding-left: 0.5rem !important;
}

.px-2 {
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}

.py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}

.pt-3 {
	padding-top: 1rem !important;
}

.pr-3 {
	padding-right: 1rem !important;
}

.pb-3 {
	padding-bottom: 1rem !important;
}

.pl-3 {
	padding-left: 1rem !important;
}

.py-3 {
	padding-bottom: 1rem !important;
	padding-top: 1rem !important;
}

.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.pt-4 {
	padding-top: 1.5rem !important;
}

.pr-4 {
	padding-right: 1.5rem !important;
}

.pb-4 {
	padding-bottom: 1.5rem !important;
}

.pl-4 {
	padding-left: 1.5rem !important;
}

.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}

.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}

.pt-5 {
	padding-top: 3rem !important;
}

.pr-5 {
	padding-right: 3rem !important;
}

.pb-5 {
	padding-bottom: 3rem !important;
}

.pl-5 {
	padding-left: 3rem !important;
}

.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}

.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}

.push-content-down-header {
	margin-top: 100px;
}

.mb-30 {
	margin-bottom: 30px;
}

/* Text properties */
.text-uppercase {
	text-transform: uppercase;
}

.text-lowercase {
	text-transform: lowercase;
}

.text-capitalize {
	text-transform: capitalize;
}

.text-transform-none {
	text-transform: none;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.font-xsm {
	font-size: x-small;
}

.qcont:first-letter {
	text-transform: capitalize
}

/* Header */
.header-light {
	position: fixed;
	width: 100%;
	z-index: 3;
	border-bottom: 1px solid rgba(51, 51, 51, 0.11);
	display: flex;
	align-items: center;
	transition: all .3s ease-in-out;
}

.header-height-80 {
	height: 80px !important;
}

.header-height-100 {
	height: 100px !important;
}

.transparent-header,
.transparent-header {
	background: transparent !important;
	border-bottom: none;
}

.top-nav .ant-menu {
	background: transparent !important;
}

.logo {
	float: left;
}

.ant-layout-header {
	padding: 0 20px !important;
}

.top-nav .ant-menu-horizontal {
	border-bottom: none;
}

.top-nav li:nth-last-child(2)::before {
	content: "";
	position: absolute;
	left: -0.5px;
	width: 0;
	border-right: solid 1px var(--themecolor);
	height: 100%;
	max-height: 100%;
	top: 50%;
	transform: translateY(-50%);
	border-color: rgba(17, 17, 17, 0.14);
	height: 24px;
	border-width: 1px;
}

.logo-lg .ant-image {
	width: 250px;
}

.logo-md .ant-image {
	width: 200px;
}

/* Footer */
.app-footer {
	background: var(--themecolor2) !important;
	color: var(--bg-white) !important;
	padding: 60px 50px 10px 50px !important;
}

.app-footer .ant-menu-horizontal {
	background: transparent;
	border-bottom: none;
}

.app-footer .ant-menu-title-content {
	color: var(--bg-white) !important;
}

.ant-menu-item:hover:after {
	border-bottom-color: transparent !important;
	width: 65% !important;
	height: 2px;
	background: var(--themecolor);
}

.top-nav .ant-menu-horizontal>.ant-menu-item:after {
	bottom: 15px;
	right: 18%;
	left: 18%;
	transition: all .3s ease-in-out;
	width: 5%;
}

.app-footer ul {
	list-style-type: none;
}

.app-footer a {
	font-size: 18px;
	color: var(--bg-white) !important;
}

.app-footer a:hover {
	color: var(--themecolor) !important;
}

/* Button */
.ant-btn:not(.ant-btn-circle, .font-20-300, .qcont) {
	text-transform: uppercase !important;
	border-radius: 3px !important;
}

.ant-btn-primary {
	border-color: var(--themecolor) !important;
	background: var(--themecolor) !important;
	font-weight: 500 !important;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 2px;
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
	border-color: var(--themecolor2) !important;
	background: var(--themecolor2) !important;
}

.hover-outline {
	color: var(--bg-white) !important;
	font-family: 'Raleway', sans-serif;
	font-size: 14px !important;
	font-weight: 600 !important;
	border-width: 2px !important;
}

.hover-outline:focus,
.hover-outline:hover {
	border-color: var(--themecolor) !important;
	background: transparent !important;
	color: #000 !important;
}

.ant-back-top {
	right: 10px !important;
	bottom: 10px !important;
	transition: opacity .4s ease;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, .2);
	opacity: 1;
	color: var(--bg-white) !important;
	font-size: x-large !important;
	text-align: center;
}

.ant-back-top:hover {
	background-color: #555;
}

.ant-pagination-item-active {
	background: var(--themecolor2) !important;
	border-color: var(--themecolor2) !important;
}

.ant-pagination-item-active a {
	color: var(--bg-white) !important;
}

.ant-pagination-item:hover:not(.ant-pagination-item-active) {
	background: #efefef;
	border-color: #efefef;
}

.ant-pagination-item a:hover:not(.ant-pagination-item-active a) {
	color: #000;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: var(--themecolor) !important;
}

/* Card */
.crd-header-style .ant-card-head {
	background: var(--themecolor2) !important;
	color: var(--bg-white) !important;
	font-size: 21px;
	line-height: 26px;
	width: 83.33333333%;
}

.crd-header-style {
	background: transparent !important;
}

.crd-header-style .ant-card-body {
	background: var(--bg-white);
	border: 1px solid #f0f0f0;
}

.ant-modal-header {
	background: var(--themecolor2) !important;
}

.ant-modal-title {
	color: var(--bg-white) !important;
}

.mobile-menu .ant-menu-inline {
	text-align: center;
	font-size: 18px;
	font-weight: 500;
	color: var(--themecolor);
	text-transform: uppercase;
}

.gray-header .ant-card-head {
	background: #f4f2f3 !important;
}

/* Text */
label:not(.ant-checkbox-wrapper) {
	font-size: 15px !important;
	text-transform: uppercase;
	font-weight: 600;
}

input[type=text],
select,
.ant-select-selector:not(.ant-pagination-options .ant-select-selector),
.ant-select-selection-search-input {
	min-height: 50px;
	height: 50px !important;
	border-radius: 3px !important;
}

.bg-position {
	background-position: unset;
}

.titlemargin {
	padding: 140px 3em;
}

.sm-margins {
	padding: 35px 3em;
}

.margins {
	padding: 70px;
}

.vc_column-inner {
	padding-top: 35px;
	padding-left: 15px;
	padding-right: 15px;
}

span.accent,
.ant-menu-horizontal>.ant-menu-item a.primary-link,
.ant-menu-horizontal>.ant-menu-item a.primary-link:hover,
.ant-menu-inline>.ant-menu-item a.primary-link,
.ant-menu-inline>.ant-menu-item a.primary-link:hover {
	color: var(--themecolor);
}

span.accent-2 {
	color: var(--themecolor2);
}

.text-white {
	color: var(--bg-white) !important;
}

.bg-white {
	background: var(--bg-white) !important;
}

.bg-secondary-theme {
	background: var(--themecolor2);
}

.label-hidden .ant-form-item-label {
	display: block;
	visibility: hidden;
}

.pc-navbar .ant-menu-title-content {
	color: var(--themecolor);
	font-weight: 500;
	font-size: 18px;
}

.pc-navbar .ant-menu-horizontal {
	display: none;
}

.app-footer .ant-menu-title-content {
	font-size: 15px;
	font-weight: 300;
}

.app-footer .ant-menu-title-content:hover:not(.app-footer li:nth-last-child(2) .ant-menu-title-content:hover) {
	text-decoration: underline;
}

.app-footer .ant-menu-item:hover:after {
	background: none;
}

.ant-layout {
	background: var(--bg-white) !important;
}

.list-checkbox {
	flex-direction: column;
	display: inline-flex !important;
}

.text-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.bg-style {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.ant-btn.outline {
	border: 2px solid var(--themecolor);
	background: transparent;
	font-size: 14px;
	font-weight: 600;
	padding: 20px 25px !important;
	letter-spacing: 2px;
	height: auto;
	line-height: 16px;
	font-family: 'Raleway', sans-serif;
}

.ant-btn.outline:hover,
.ant-btn.outline:focus {
	border: 2px solid var(--themecolor2);
	background: var(--themecolor2);
	color: var(--bg-white);
}

.label-text {
	font-size: 16px !important;
	text-transform: uppercase;
	font-weight: 600;
	margin-right: 10px;
}

p,
.ant-typography,
li {
	font-weight: 300;
	font-size: 20px;
}

.sm-text {
	font-size: 15px !important;
	font-weight: 100 !important;
}

.font-20 {
	font-size: 20px;
}

.ant-layout-content {
	min-height: 63vh !important;
}

.margins-lg {
	padding: 260px 3em;
}

.h-unset {
	height: unset !important;
}

.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

.theme-check .ant-checkbox-inner {
	border: 1px solid var(--themecolor);
}

.featured-company .ant-image-img {
	border: 1px solid lightgray;
}

.cv-upload-form.ant-form {
	border: 1px solid lightgray;
	margin-top: 20px;
	padding: 20px;
	background-color: #fafafa;
}

.place-center {
	display: grid;
	place-content: center;
}

.custom-multi-select.ant-select-multiple .ant-select-selector {
	height: unset !important;
}

.label-capitalize-first {
	text-transform: lowercase;
}

.f-20 {
	font-size: 20px;
	font-weight: 300;
}

.break-word {
	white-space: break-spaces;
}

.list-hover {
	padding: 12px 24px !important;
}

.list-hover:hover {
	background-color: rgb(235, 235, 235);
}

.list-hover:first-child{
	background-color: rgb(235, 235, 235);
}

.cv-list .ant-list-items{
	border-top: 6px solid var(--themecolor);
}

.table-radio .ant-radio-wrapper {
	width: 100%;
}

.table-radio .ant-radio-wrapper span:nth-child(2):not(.updated-date, .ant-radio-inner) {
	width: 100%;
	display: flex;
	align-content: center;
	justify-content: space-between;
}

.form-group-options .ant-radio-group label,
.form-group-options .ant-checkbox-group label {
	font-weight: 100 !important;
	text-transform: capitalize !important;
}

.article-image {
	border-radius: 2rem 2rem 0 0;
}

.intro-card-position {
	position: relative;
	bottom: 210px;
	z-index: 2;
}

.intro-card .ant-card-bordered {
	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid #707070 !important;
}

.intro-card .ant-card-body {
	background-color: #006CA4;
	min-height: 187px;
}

.logo-container {
	width: 200px;
	height: 200px;
	border-radius: var(--rounded-2xl);
	position: absolute;
	top:-200px;
}

.h-full {
	height: 100%;
}

.gray-card .ant-card {
	border-radius: var(--rounded-2xl);
}

.gray-card .ant-card-body {
	background-color: #f2f4f8;
	min-height: 200px;
}

.border-dotted {
	border: 1px dashed #006CA4;
}

.border-solid {
	border: 1px solid #efefef;
}

.color-blue {
	color: #006CA4 !important;
}

.bg-blue {
	background-color: #006CA4;
}

button.bg-green {
	background-color: #BFD12B !important;
	border-color: #BFD12B !important;
}

.rounded-2xl {
	border-radius: var(--rounded-2xl);
}

.rounded-2xl-card .ant-card-body,
.rounded-2xl-card .ant-card {
	border-radius: var(--rounded-2xl);
}

.video-container iframe {
	overflow: hidden;
	border-radius: var(--rounded-2xl);
	width: 100%;
	height: 100vh;
}

.video-container .video-description {
	position: absolute;
	bottom: 5px;
	width: 100%;
	border-bottom-right-radius: var(--rounded-2xl);
	border-bottom-left-radius: var(--rounded-2xl);
	background: rgb(2, 0, 36);
	background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(89, 89, 111, 0.6530987394957983) 44%, rgba(0, 0, 0, 0.7091211484593838) 100%);
}

.text-base {
	font-size: 1rem;
	line-height: 1.5rem;
}

.text-4xl {
	font-size: 2.25rem;
	line-height: 2.5rem;
}

.testimonials-card .ant-card {
	overflow: hidden;
	box-shadow: 0px 10px 35px #00000008;
	border: 1px solid #E5EAF4;
}

.testimonials-card .ant-card-cover {
	margin-top: -2px !important;
	margin-right: -2px !important;
	margin-left: -2px !important;
}

.h-story {
	height: 40vh !important;
}

.curved-collapse {
	background: transparent !important;
}

.curved-collapse .ant-collapse-item {
	margin-bottom: 24px;
	background: #F1F3F8;
	border-radius: var(--rounded-2xl) !important;
	border: none !important;
}

.curved-collapse .ant-collapse-header-text,
.curved-collapse .ant-collapse-expand-icon {
	color: #006CA4;
	font-weight: bold;
}

.article-snippet .ant-card {
	z-index: 2;
}

.article-snippet::before, .article-snippet::after{
	display: none;
}

.blog-footer-min-height {
	min-height: 534px;
}

.curved-slider.multi-img{
	display: none;
}

.curved-slider .slide {
	height: 245px;
	margin: 0 15px;
	background-color: #f2f4f8;
	border-radius: var(--rounded-2xl);
	overflow: hidden;
	display: grid;
	place-content: center;
}

.curved-slider,
.relative {
	position: relative;
}

.curved-slider .anticon {
	position: absolute;
	top: 40%;
	z-index: 2;
	background-color: var(--bg-white);
	border-radius: 50%;
}

.curved-slider .anticon-right-circle {
	right: 0;
}

.curved-slider.xs-slider .slide{
	height: 200px;
}

.blog-intro-bg {
	border-bottom-left-radius: 48% 95px;
	border-bottom-right-radius: 48% 95px;
	overflow: hidden;
	height: 65vh;
}

.blog-intro-bg img {
	display: block;
	width: 100vw;
	height: 60vh;
	object-fit: cover;
}

.curved-footer {
	position: absolute;
	left: 0;
	top: -135px;
	overflow: hidden;
	height: 238px;
	width: 100%;
	background: var(--themecolor2);
}

.curved-footer::before {
	content: '';
	position: absolute;
	bottom: 100px;
	left: 47%;
	width: 130vh;
	height: 100vh;
	background: var(--bg-white);
	background-repeat: no-repeat;
	border-radius: 48%;
	transform-origin: bottom;
	transform: translateX(-50%) scale(4);
}

.upwards-line:hover:after{
	opacity: 1;
  transform: translateY(0px);
	height: 2px;
	background: var(--bg-white);
}
.upwards-line:after{
	position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
	background: none;
	content: '';
	transition: opacity .3s,transform .3s;
	transform: translateY(10px);
}

.curved-bg {
	position: relative;
	overflow: hidden;
	height: 400px;
}

.curved-bg::before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 100vh;
	height: 100vh;
	background: var(--themecolor2);
	background-repeat: no-repeat;
	border-radius: 48%;
	transform-origin: bottom;
	transform: translateX(-50%) scale(4);
}

.curved-bg::after{
	content: '';
  position: absolute;
  width: 237px;
  height: 200px;
  z-index: 1;
  background: var(--bg-white) 0% 0% no-repeat padding-box;
  box-shadow: -50px 50px 50px #00000026;
  border-radius: 16px;
  opacity: 0.06;
  right: 40px;
  top: 40px;
}
.xs-logo-placement.mb-4{
	margin-top: 40px !important;
}
.xs-logo-placement .job-logo-placement{
	text-align: right;
	height: 140px;
}

.xs-logo-placement .job-logo-placement .company-logo{
	position: relative;
	top: -60px;
	width: 220px;
	height: 200px;
	margin-left: auto;
  display: grid;
  place-content: center;
}
.scroll-to-offset{
	position: absolute;
    top: -100px;
}
@media screen and (max-width: 1400px) {
	.margins-lg {
		padding: 220px 0.5em;
	}
}

@media screen and (max-width: 767px) {
	.bg-position {
		background-position: 57% 0%;
	}

	.titlemargin {
		padding: 120px 0.5em 20px;
	}

	.sm-margins {
		padding: 35px 0.5em;
	}

	.margins {
		padding: 30px;
	}

	.label-hidden .ant-form-item-label {
		display: none;
	}

	.crd-header-style .ant-card-head {
		width: 100%;
	}

	.margins-lg {
		padding: 120px 0.5em;
	}

	.header-light .container {
		width: 100%;
	}
}

@media (min-width: 576px) {
	.curved-slider.xs-slider{
		display: none;
	}
	.curved-slider.multi-img{
		display: block;
	}
}

@media (min-width: 768px) {
	.container {
		width: 750px;
	}

	.text-lg-center {
		text-align: center;
	}

	.logo-lg .ant-image {
		width: 350px;
	}

	.article-image {
		border-radius: 2rem 0 0 2rem;
	}

	.article-card .writer-details {
		width: 100%;
		bottom: 0;
		position: absolute !important;
	}
	.article-snippet .ant-card{
		width: 466px;
	}
	
	.article-snippet::before {
		content: '';
		display: block;
		position: absolute;
		width: 237px;
		height: 200px;
		z-index: 1;
		background: var(--bg-white) 0% 0% no-repeat padding-box;
		box-shadow: -50px 50px 50px #00000026;
		border-radius: 16px;
		opacity: 0.06;
		bottom: -22%;
		left: 53px;
	}
	
	.article-snippet::after {
		content: '';
		display: block;
		position: absolute;
		width: 237px;
		height: 200px;
		z-index: 1;
		background: var(--themecolor) 0% 0% no-repeat padding-box;
		box-shadow: -50px 50px 50px #00000026;
		border-radius: 16px;
		opacity: 1;
		right: 40px;
		top: 35px;
	}

	.xs-logo-placement.mb-4{
		margin-top: 0 !important;
	}
	.xs-logo-placement .job-logo-placement{
		height: unset;
	}
	
	.xs-logo-placement .job-logo-placement .company-logo{
		position: unset;
		top: unset;
		width: unset;
		height: unset;
		margin-left: unset;
		border: none !important;
		place-content: end;
	}

	.xs-logo-placement .job-logo-placement .company-logo .p-2{
		padding-right: 0 !important;
	}
}

@media (min-width: 992px) {
	.w-first-col {
		min-width: 600px;
	}

	.ml-lg-5 {
		margin-left: 3rem !important;
	}

	.mobile-menu-div {
		display: none;
	}

	.pc-navbar .ant-menu-horizontal {
		display: block;
	}

	.top-nav .ant-menu-horizontal {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		min-width: 70%;
	}

	.ant-layout-header {
		padding: 0 50px !important;
	}

	.container {
		width: 970px;
	}
}

@media (min-width: 1200px) {
	.container {
		width: 1200px;
	}
	.logo-container{
		top: -67px;
	}
}
