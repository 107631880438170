.theme-2-bg {
  background-color: var(--themecolor2);
}

.primary-border-top {
  border-top: 16px solid var(--themecolor);
}

.font-20-300 {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-transform: capitalize !important;
}

.border-right:not(.border-right:nth-child(3n)) {
  border: solid;
  border-color: #efefef;
  border-width: 0px 1px 0px 0px;
}

.flex-center-column {
  display: flex !important;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.bg-gray {
  background-color: #f4f2f3 !important;
}

.icon-lg.ant-btn-icon-only.ant-btn-lg {
  width: 2em;
  height: 2em;
  font-size: 32px;
}

.icon-lg.ant-btn-icon-only.ant-btn-lg>* {
  font-size: 32px;
}

.w-100 {
  width: 100%;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-form .ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 45px !important;
}

.search-form .ant-btn-lg {
  height: 50px;
  width: 100%;
}

.button-font{
  font-size: 18px !important;
}

.occ-hover:hover {
  color: var(--themecolor) !important;
}

.pt-35 {
  padding-top: 35px;
}

.pt-20 {
  padding-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.video-container {
  width: 100%;
  position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

.video-container .find-jobs-form {
  z-index: 1;
}
.upb_bg_overlay{
  background-color:rgba(0,0,0,0.2);
}
/* .upb_bg_overlay_pattern{
  background-image: url('../../assets/01.png');
  opacity: 0.3;
  background-attachment: scroll;
} */
.upb_bg_overlay, .upb_bg_overlay_pattern {
  color: inherit;
  position: absolute;
  width: 100%;
  height: 100%!important;
  left: 0;
  top: 0;
}
.gap-5{
  display: flex;
  align-items: center;
  gap: 5px;
}