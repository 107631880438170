.filter-panel .ant-collapse-header{
  display: block !important;
}

@media (min-width: 992px) {
  .filter-panel .ant-collapse-header{
    display: none !important;
  }

  .filter-panel .ant-collapse-content-box{
    padding: 0 !important;
  }
}