.article-card .ant-card-body{
    padding: 0;
}
.article-card{
    border-radius: 2rem;
    /* height: 380px; */
}
.article-card h4{
    font-weight: 600;
}
.article-card .writer-details{
    position: relative;
}
.article-content{
    padding: 20px;
}
.article-text{
    font-size: small;
}
.verified-icon{
   color: #36B37E;
}

.search-box-card{
    background-color: #f2f4f8;
    box-shadow: 0px 10px 35px #00000008;
    border: 1px solid #E5EAF4;
    border-radius: 16px;
    opacity: 1;
    width: 100%;
}
.search-box-title{
    text-align: left;
    font: normal normal medium 36px/48px Raleway;
    letter-spacing: 0.2px;
    color: #183B56;
    opacity: 1;
    font-weight: 600;
}
.curved-image{
    /* min-width: 750px; */
    /* height: 360px; */
    overflow: hidden;
    border-radius: 16px;
}
.sub-text-content{
    text-align: left;
    font: normal normal normal 16px/28px Open Sans;
    letter-spacing: 0px;
    color: #5A7184;
    opacity: 1;
    font-weight: 250;
}
.writer-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}
.writer-content .ant-card-meta-title{margin-bottom:0 !important;}
.ant-card-meta-detail .ant-card-meta-title{margin-bottom:0 !important;}